import React from "react";
import { Select, MenuItem } from "@mui/material";

const SelectPeriod = ({ period, setPeriod, color }) => {
    return (
        <Select
            value={period}
            style={{ width: "150px", color }}
            onChange={(e) => setPeriod(e.target.value)}
        >
            <MenuItem value="today">Today</MenuItem>
            <MenuItem value="yesterday">Yesterday</MenuItem>
            <MenuItem value="thisWeek">This Week</MenuItem>
            <MenuItem value="lastWeek">Last Week</MenuItem>
            <MenuItem value="thisMonth">This Month</MenuItem>
            <MenuItem value="lastMonth">Last Month</MenuItem>
            <MenuItem value="total">Total</MenuItem>
        </Select>
    )
}

export default SelectPeriod;