export const validateText = (text) => {
    if (text !== "") {
        return true;
    } else {
        return false;
    }
};

export const validateMaxDiscountChange = (e) => {
    const maxDiscount = e.target.value < 0 ? 0 : e.target.value > 40 ? 40 : e.target.value;
    return maxDiscount;
}

export const validateMaxDiscountSubmit = (maxDiscount) => {
    const regex = /^\d+$/;
    if (maxDiscount !== "" && regex.test(maxDiscount) && parseInt(maxDiscount) >= 0 && parseInt(maxDiscount) <= 40) {
        return true;
    } else {
        return false;
    }
};

export const UTC_EASTERN_TIME_ZONE_OFFSET = -5;

export const calculateMinMaxTime = (period) => {
    let minTime = undefined;
    let maxTime = new Date();
    const maxTimeUtc = maxTime.getTime() + (maxTime.getTimezoneOffset() * 60000);
    maxTime = new Date(maxTimeUtc + (3600000 * UTC_EASTERN_TIME_ZONE_OFFSET));
    let today = new Date();
    const todayUtc = today.getTime() + (today.getTimezoneOffset() * 60000);
    today = new Date(todayUtc + (3600000 * UTC_EASTERN_TIME_ZONE_OFFSET)).setUTCHours(Math.abs(UTC_EASTERN_TIME_ZONE_OFFSET), 0, 0, 0);
    const thisSunday = new Date(today).getDate() - new Date(today).getDay();
    const lastSunday = thisSunday - 7;
    const thisMonth = new Date(new Date(today).getFullYear(), new Date(today).getMonth(), 1);
    const lastMonth = new Date(new Date(today).getFullYear(), new Date(today).getMonth() - 1, 1);
    if (period === "today") {
        minTime = new Date(today);
    } else if (period === "yesterday") {
        minTime = new Date(today - (24 * 60 * 60 * 1000));
        maxTime = new Date(today);
    } else if (period === "thisWeek") {
        minTime = new Date(new Date(today).setDate(thisSunday));
    } else if (period === "lastWeek") {
        minTime = new Date(new Date(today).setDate(lastSunday));
        maxTime = new Date(new Date(today).setDate(lastSunday + 7));
    } else if (period === "thisMonth") {
        minTime = new Date(thisMonth);
    } else if (period === "lastMonth") {
        minTime = new Date(lastMonth);
        maxTime = new Date(thisMonth);
    } else if (period === "total") {
        minTime = new Date(0);
    }
    return { minTime, maxTime };
};