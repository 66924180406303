import React, { useState, useRef, useEffect } from "react";
import { Card, Select, MenuItem, TextField, InputAdornment, Tooltip, IconButton, Slider, Button } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import axios from "axios";
import Loader from "../UI/Loader";
import { validateText } from "../../helpers/inputValidation";

const IMAGE_PATH =  "/img";

const DEFAULT_POPUP_FONT = "Arial";
const DEFAULT_POPUP_DISCOUNT_CODE_PREFIX = "VANDRA";
const DEFAULT_POPUP_PRIMARY_COLOR = "856cf8";
const DEFAULT_POPUP_BACKGROUND_COLOR = "ffffff";
const DEFAULT_POPUP_BORDER_COLOR = "000000";
const DEFAULT_POPUP_BORDER_RADIUS = 0;
const DEFAULT_MAX_DISCOUNT = 20;
const DEFAULT_COPY = "copy";
const DEFAULT_IS_ACTIVATED = false;
const DEFAULT_IS_DISABLED = true;
const DEFAULT_IS_PAGE_LOADING = false;

const PARAMS = window.location.search;
const DISCOUNT_CODE_SUFFIX = "-03jfm";
// To-Do: RegEx
const DISCOUNT_CODE_PREFIX_BLOCKLIST = [" ", "<", ">", "'", "\"", "{", "}"];
const HEX_KEYS = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "a", "b", "c", "d", "e", "f", "A", "B", "C", "D", "E", "F"];

const CardCustom = styled(Card)(({ theme }) => ({
    border: "1px solid #E0E0E0",
    borderRadius: "1rem",
    boxShadow: "none"
}));

const Widget = () => {
    const [popupFont, setPopupFont] = useState(DEFAULT_POPUP_FONT);
    const [popupDiscountCodePrefix, setPopupDiscountCodePrefix] = useState(DEFAULT_POPUP_DISCOUNT_CODE_PREFIX);
    const [popupPrimaryColor, setPopupPrimaryColor] = useState(DEFAULT_POPUP_PRIMARY_COLOR);
    const [popupBackgroundColor, setPopupBackgroundColor] = useState(DEFAULT_POPUP_BACKGROUND_COLOR);
    const [popupBorderColor, setPopupBorderColor] = useState(DEFAULT_POPUP_BORDER_COLOR);
    const [popupBorderRadius, setPopupBorderRadius] = useState(DEFAULT_POPUP_BORDER_RADIUS);
    const [maxDiscount, setMaxDiscount] = useState(DEFAULT_MAX_DISCOUNT);
    const [copy, setCopy] = useState(DEFAULT_COPY);
    const [isActivated, setIsActivated] = useState(DEFAULT_IS_ACTIVATED);
    const [isDisabled, setIsDisabled] = useState(DEFAULT_IS_DISABLED);
    const [isPageLoading, setIsPageLoading] = useState(DEFAULT_IS_PAGE_LOADING);
    const applyContent = useRef();
    const appliedContent = useRef();

    useEffect(() => {
        getWidgetSettings();
    }, []);

    const getWidgetSettings = async () => {
        setIsPageLoading(true);
        try {
            const widgetSettings = await axios.get("/react/merchant_settings" + PARAMS);
            if (widgetSettings.status === 200) {
                if (widgetSettings.data?.popup_font !== undefined) {
                    setPopupFont(widgetSettings.data.popup_font);
                }
                if (widgetSettings.data?.popup_discount_code_prefix !== undefined) {
                    setPopupDiscountCodePrefix(widgetSettings.data.popup_discount_code_prefix);
                }
                if (widgetSettings.data?.popup_primary_color !== undefined) {
                    setPopupPrimaryColor(widgetSettings.data.popup_primary_color);
                }
                if (widgetSettings.data?.popup_bg_color !== undefined) {
                    setPopupBackgroundColor(widgetSettings.data.popup_bg_color);
                }
                if (widgetSettings.data?.max_discount !== undefined) {
                    setMaxDiscount(widgetSettings.data.max_discount);
                }
            } else {
                throw new Error(widgetSettings.status);
            }
        } catch (err) {
            toast.error("Error");
            console.error(err);
        }
        setIsPageLoading(DEFAULT_IS_PAGE_LOADING);
    };

    const handlePopupFontChange = (e) => {
        const popupFont = e.target.value;
        setPopupFont(popupFont);
        setIsDisabled(false);
    };

    const handlePopupDiscountCodePrefixChange = (e) => {
        const popupDiscountCodePrefix = e.target.value;
        setPopupDiscountCodePrefix(popupDiscountCodePrefix);
        setIsDisabled(false);
    };

    const handlePopupPrimaryColorChange = (e) => {
        const popupPrimaryColor = e.target.value.replace("#", "");
        setPopupPrimaryColor(popupPrimaryColor);
        setIsDisabled(false);
    };

    const handlePopupBackgroundColorChange = (e) => {
        const popupBackgroundColor = e.target.value.replace("#", "");
        setPopupBackgroundColor(popupBackgroundColor);
        setIsDisabled(false);
    };

    const handleCancelButtonClick = () => {
        getWidgetSettings();
        setIsDisabled(DEFAULT_IS_DISABLED);
    };

    const handleSaveAllChangesButtonClick = async () => {
        try {
            if (!validateText(popupDiscountCodePrefix)) {
                toast.error("Error: Please enter a discount code prefix.");
                return;
            }
            const save = await axios.post("/react/merchant_widget_settings_save" + PARAMS, {
                "popup_font": popupFont,
                "popup_discount_code_prefix": popupDiscountCodePrefix,
                "popup_primary_color": `#${popupPrimaryColor}`,
                "popup_bg_color": `#${popupBackgroundColor}`,
            }, { headers: { "Content-Type": "application/x-www-form-urlencoded" } });
            if (save.status === 200) {
                toast.success("Saved");
                setIsDisabled(DEFAULT_IS_DISABLED);
            } else {
                toast.error("Error");
                throw new Error(save.status);
            }
        } catch (err) {
            toast.error("Error");
            console.error(err);
        }
    };

    const handleCopyClick = () => {
        navigator.clipboard.writeText(popupDiscountCodePrefix + DISCOUNT_CODE_SUFFIX);
        setCopy("copied!");
        setTimeout(() => {
            setCopy(DEFAULT_COPY);
        }, 3000);
    };

    const handleActivateClick = () => {
        const clearApplyContentForwards = () => {
            const clearAppliedContentForwards = () => {
                appliedContent.current.classList.remove("slide-left-to-center");
                appliedContent.current.removeEventListener("animationend", clearAppliedContentForwards);
            };
            setIsActivated(true);
            applyContent.current.classList.add("invisible");
            applyContent.current.classList.remove("slide-center-to-right");
            applyContent.current.removeEventListener("animationend", clearApplyContentForwards);
            appliedContent.current.addEventListener("animationend", clearAppliedContentForwards, { passive: true });
            appliedContent.current.classList.add("slide-left-to-center");
        };
        const clearAppliedContentReverse = () => {
            const clearApplyContentReverse = () => {
                applyContent.current.classList.remove("slide-left-to-center");
                applyContent.current.removeEventListener("animationend", clearApplyContentReverse);
            };
            setIsActivated(DEFAULT_IS_ACTIVATED);
            appliedContent.current.classList.add("invisible");
            appliedContent.current.classList.remove("slide-center-to-right");
            appliedContent.current.removeEventListener("animationend", clearAppliedContentReverse);
            applyContent.current.addEventListener("animationend", clearApplyContentReverse, { passive: true });
            applyContent.current.classList.add("slide-left-to-center");
        };
        applyContent.current.classList.remove(...applyContent.current.classList);
        appliedContent.current.classList.remove(...appliedContent.current.classList);
        if (!isActivated) {
            applyContent.current.addEventListener("animationend", clearApplyContentForwards, { passive: true });
            applyContent.current.classList.add("slide-center-to-right");
        } else {
            appliedContent.current.addEventListener("animationend", clearAppliedContentReverse, { passive: true });
            appliedContent.current.classList.add("slide-center-to-right");
        }
    };

    return (
        <div className="flex-col-center">
            {isPageLoading ?
                <div className="content flex-col-center gap full">
                    <Loader />
                </div> :
                <div className="content flex-col-left gap full">
                    <div className="flex-row-center-top flex-col-left-mobile gap full">
                        <div className="flex-col-left gap one-third full-mobile">
                            <CardCustom className="flex-col-left gap full full-mobile card">
                                <h3>Font & Text Adjustment</h3>
                                <img src={`${IMAGE_PATH}/misc/Divider-Horizontal.png`} alt="Divider Horizontal" className="full" />
                                <div className="flex-col-left full">
                                    <div className="flex-row-left">
                                        <p>Choose Font:</p>
                                        <Tooltip placement="top" title="Some custom fonts may not display in the live preview but will on your site.">
                                            <IconButton>
                                                <InfoIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <TextField variant="outlined" value={popupFont} className="full" onChange={(e) => handlePopupFontChange(e)} />
                                </div>
                                <div className="flex-col-left full">
                                    <p>Discount Code Prefix:</p>
                                    <TextField
                                        inputProps={{ maxLength: 7 }}
                                        onKeyPress={(e) => {
                                            if (DISCOUNT_CODE_PREFIX_BLOCKLIST.includes(e?.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        value={popupDiscountCodePrefix}
                                        className="full"
                                        onChange={(e) => handlePopupDiscountCodePrefixChange(e)} />
                                </div>
                            </CardCustom>
                            <CardCustom className="flex-col-left gap full full-mobile card">
                                <h3>Color Theme Adjustment</h3>
                                <img src={`${IMAGE_PATH}/misc/Divider-Horizontal.png`} alt="Divider Horizontal" className="full" />
                                <div className="flex-col-left full">
                                    <p>Primary Color (Hex):</p>
                                    <div className="flex-row-left gap full">
                                        <input value={`#${popupPrimaryColor}`} type="color" style={{ width: "25px", height: "25px" }} onChange={(e) => handlePopupPrimaryColorChange(e)} />
                                        <TextField value={popupPrimaryColor}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">#</InputAdornment>,
                                            }}
                                            onKeyPress={(e) => {
                                                if (e.target.value.length >= 6) {
                                                    e.preventDefault();
                                                }
                                                if (!HEX_KEYS.includes(e?.key)) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            className="full"
                                            onChange={(e) => handlePopupPrimaryColorChange(e)} />
                                    </div>
                                </div>
                                <div className="flex-col-left full">
                                    <p>Background Color (Hex):</p>
                                    <div className="flex-row-left gap full">
                                        <input value={`#${popupBackgroundColor}`} type="color" style={{ width: "25px", height: "25px" }} onChange={(e) => handlePopupBackgroundColorChange(e)} />
                                        <TextField value={popupBackgroundColor}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">#</InputAdornment>,
                                            }}
                                            onKeyPress={(e) => {
                                                if (e.target.value.length >= 6) {
                                                    e.preventDefault();
                                                }
                                                if (!HEX_KEYS.includes(e?.key)) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            className="full"
                                            onChange={(e) => handlePopupBackgroundColorChange(e)} />
                                    </div>
                                </div>
                            </CardCustom>
                        </div>
                        <div style={{ position: "relative", alignSelf: "stretch" }} className="two-thirds full-mobile">
                            <div style={{ position: "sticky", top: "90px" }} className="flex-col-center gap full">
                                <CardCustom style={{ position: "relative", padding: "4rem", background: "#EEEDF3" }} className="flex-row-center flex-col-center-mobile gap-lg full">
                                    <h3 style={{ position: "absolute", top: 12, left: 20, margin: 0 }}>Live Preview</h3>
                                    <div id="vandra_widget_div" style={{ position: "relative", width: "322px", borderRadius: "10px 0px 0px 10px", padding: "16px", fontFamily: popupFont + ", Arial", backgroundColor: `#${popupBackgroundColor}`, boxShadow: "0px 0px 16px rgb(0 0 0 / 12%)", overflow: "hidden" }}>
                                        <div id="vandra_widget_close" style={{ position: "absolute", top: "10px", right: "10px", width: "15px", height: "15px", fill: `#${popupPrimaryColor}`, cursor: "pointer" }}>
                                            <svg viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M 15 1.5107 L 13.4893 0 L 7.5 5.9893 L 1.5107 0 L 0 1.5107 L 5.9893 7.5 L 0 13.4893 L 1.5107 15 L 7.5 9.0107 L 13.4893 15 L 15 13.4893 L 9.0107 7.5 L 15 1.5107 Z">
                                                </path>
                                            </svg>
                                        </div>
                                        <div className="vandra_content" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }}>
                                            <div style={{ margin: "0px 0px 2px 0px", fontSize: "25px", fontWeight: "bold", lineHeight: "25px" }}>GET {maxDiscount}% OFF NOW</div>
                                            <div style={{ margin: "0px 0px 20px 0px", fontSize: "15px", lineHeight: "16px" }}>Valid for one day only. Don't miss out!</div>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%", margin: "0px 0px 8px 0px", border: "2px dotted #777777" }}>
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                                    <img src={`${IMAGE_PATH}/widget/Widget-Preview-Discount.png`} alt="Widget Preview Discount" />
                                                    <p style={{ margin: "10px", fontSize: "15px", lineHeight: "16px", whiteSpace: "nowrap" }}>{popupDiscountCodePrefix + DISCOUNT_CODE_SUFFIX}</p>
                                                </div>
                                                <p id="vandra_widget_copy_text" style={{ width: "100px", margin: "12px", textAlign: "center", fontSize: "15px", lineHeight: "16px", textDecoration: "underline", whiteSpace: "nowrap", cursor: "pointer" }} onClick={handleCopyClick}>{copy}</p>
                                            </div>
                                            <button id="vandra_widget_accept_button" style={{ position: "relative", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%", height: "38px", margin: "0px", border: "1px solid #626262", borderRadius: "3px", fontSize: "17px", fontWeight: "bold", lineHeight: "38px", color: "#fff", backgroundColor: `#${popupPrimaryColor}`, cursor: "pointer", overflow: "hidden" }} onClick={handleActivateClick} aria-label="Simulate Animation">
                                                <div ref={appliedContent} style={{ display: isActivated ? "flex" : "none", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "0.5rem" }}>
                                                    <img src={`${IMAGE_PATH}/widget/Activate-Button-Success.png`} alt="Activate Button Success" />
                                                    <span style={{ fontFamily: popupFont + ", Arial" }}>Applied</span>
                                                </div>
                                                <div ref={applyContent} style={{ display: isActivated ? "none" : "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                    <span style={{ fontFamily: popupFont + ", Arial" }}>Apply Discount</span>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                    <div id="vandra_minimized_div" style={{ position: "relative", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "30px", height: "200px", borderRadius: "10px 0px 0px 10px", padding: "16px 8px 16px 8px", fontFamily: popupFont + ", Arial", backgroundColor: `#${popupPrimaryColor}`, boxShadow: "0px 0px 16px rgb(0 0 0 / 12%)", overflow: "hidden", cursor: "pointer" }}>
                                        <div style={{ margin: "0px", textAlign: "center", fontSize: "15px", fontWeight: "bold", lineHeight: "16px", whiteSpace: "nowrap", color: "#ffffff", transform: "rotate(-90deg)" }}>
                                            GET {maxDiscount}% OFF NOW
                                        </div>
                                    </div>
                                </CardCustom>
                                <div className="flex-row-right gap full">
                                    <Button variant="outlined" style={{ display: isDisabled ? "none" : "", border: "2px solid #856CF8", color: "#856CF8" }} onClick={handleCancelButtonClick} aria-label="Cancel">Cancel</Button>
                                    <Button variant="contained" style={{ color: "#fff", backgroundColor: "#856CF8", opacity: isDisabled ? "0.5" : "" }} onClick={handleSaveAllChangesButtonClick} disabled={isDisabled} aria-label="Save All Changes">Save All Changes</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            }
        </div >
    );
}

export default Widget;

/* 
Use: Additional widget customization inputs
Date: 05/01/2023
*/

{/*
<div className="flex-col-left full">
    <p>Primary Text:</p>
    <TextField variant="outlined" className="full" />
</div>
<div className="flex-col-left full">
    <p>Secondary Text:</p>
    <TextField variant="outlined" className="full" />
</div>
<CardRounded className="flex-col-left full card">
    <h3>Box Adjustment</h3>
    <img src={`${IMAGE_PATH}/misc/Divider-Horizontal-Long.png`} alt="Divider Horizontal Long" />
    <div className="flex-row-center gap full">
        <div className="flex-col-left one-half">
            <p>Box Color:</p>
            <MuiColorInput value={popupBorderColor} onChange={(rgb) => setPopupBorderColor(rgb)} />
        </div>
        <div className="flex-col-left one-half">
            <p>Widget Position:</p>
            <Select
                value={"center"}
                className="full"
                onChange={() => { return }}
            >
                <MenuItem value={"center"}>Center</MenuItem>
            </Select>
        </div>
    </div>
    <div className="flex-col-left full">
        <p>Rounded Corners:</p>
        <div className="flex-row-left gap one-half">
            <Slider value={popupBorderRadius} onChange={(e) => setPopupBorderRadius(e.target.value)} />
            <p>{popupBorderRadius}px</p>
        </div>
    </div>
</CardRounded>
*/}