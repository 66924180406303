import React, { useState, useEffect } from "react";
import { Card, Switch, Select, MenuItem, TextField, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import axios from "axios";
import Loader from "../UI/Loader";
import { validateMaxDiscountChange, validateMaxDiscountSubmit } from "../../helpers/inputValidation";

const IMAGE_PATH =  "/img";

const DEFAULT_SHOP_URL = "";
const DEFAULT_SHOW_DISCOUNT = true;
const DEFAULT_MAX_DISCOUNT = 20;
const DEFAULT_SHOW_DISCOUNT_LOGGED_IN = false;
const DEFAULT_COMMISSION_RATE = 5;
const DEFAULT_EXCLUDE_TAGS = 0;
const DEFAULT_IS_DISABLED = true;
const DEFAULT_IS_PAGE_LOADING = false;

const PARAMS = window.location.search;

const Discount = () => {
    const [shopUrl, setShopUrl] = useState(DEFAULT_SHOP_URL);
    const [showDiscount, setShowDiscount] = useState(DEFAULT_SHOW_DISCOUNT);
    const [maxDiscount, setMaxDiscount] = useState(DEFAULT_MAX_DISCOUNT);
    const [showDiscountLoggedIn, setShowDiscountLoggedIn] = useState(DEFAULT_SHOW_DISCOUNT_LOGGED_IN);
    const [commissionRate, setCommissionRate] = useState(DEFAULT_COMMISSION_RATE);
    const [excludeTags, setExcludeTags] = useState(DEFAULT_EXCLUDE_TAGS);
    const [isDisabled, setIsDisabled] = useState(DEFAULT_IS_DISABLED);
    const [isPageLoading, setIsPageLoading] = useState(DEFAULT_IS_PAGE_LOADING);

    useEffect(() => {
        getDiscountSettings();
    }, []);

    const getDiscountSettings = async () => {
        setIsPageLoading(true);
        try {
            const discountSettings = await axios.get("/react/merchant_settings" + PARAMS);
            if (discountSettings.status === 200) {
                if (discountSettings.data?.url !== undefined) {
                    setShopUrl(discountSettings.data.url.split(".")[0]);
                }
                if (discountSettings.data?.show_discount !== undefined) {
                    setShowDiscount(discountSettings.data.show_discount);
                }
                if (discountSettings.data?.max_discount !== undefined) {
                    setMaxDiscount(discountSettings.data.max_discount);
                }
                if (discountSettings.data?.show_discount_when_logged_in !== undefined) {
                    setShowDiscountLoggedIn(discountSettings.data.show_discount_when_logged_in);
                }
                if(discountSettings.data?.commission_rate !== undefined) {
                    const commissionRate = parseFloat(discountSettings.data.commission_rate);
                    setCommissionRate(commissionRate);
                }
                if (discountSettings.data?.exclude_tags !== undefined) {
                    setExcludeTags(discountSettings.data.exclude_tags);
                }
            } else {
                throw new Error(discountSettings.status);
            }
        } catch (err) {
            toast.error("Error");
            console.error(err);
        }
        setIsPageLoading(DEFAULT_IS_PAGE_LOADING);
    };

    const handleShowDiscountChange = (e) => {
        const showDiscount = e.target.value;
        setShowDiscount(showDiscount);
        setIsDisabled(false);
    }

    const handleMaxDiscountChange = (e) => {
        const maxDiscount = validateMaxDiscountChange(e);
        setMaxDiscount(maxDiscount);
        setIsDisabled(false);
    };

    const handleShowDiscountLoggedInChange = (e) => {
        const showDiscountLoggedIn = e.target.value;
        setShowDiscountLoggedIn(showDiscountLoggedIn);
        setIsDisabled(false);
    }

    const handleCancelButtonClick = () => {
        getDiscountSettings();
        setIsDisabled(DEFAULT_IS_DISABLED);
    };

    const handleSaveAllChangesButtonClick = async () => {
        try {
            if (!validateMaxDiscountSubmit(maxDiscount)) {
                toast.error("Error: Please enter a max discount value between 0 and 40.");
                return;
            }
            const save = await axios.post("/react/merchant_discount_settings_save" + PARAMS, {
                "show_discount": showDiscount,
                "max_discount": maxDiscount,
                "show_discount_when_logged_in": showDiscountLoggedIn,
            }, { headers: { "Content-Type": "application/x-www-form-urlencoded" } });
            if (save.status === 200) {
                toast.success("Saved");
                setIsDisabled(DEFAULT_IS_DISABLED);
            } else {
                throw new Error(save.status);
            }
        } catch (err) {
            toast.error("Error");
            console.error(err);
        }
    };

    return (
        <div className="flex-col-center">
            {isPageLoading ?
                <div className="content flex-col-center gap full">
                    <Loader />
                </div> :
                <div className="content flex-col-left gap full">
                    <h3>Set Custom Parameters</h3>
                    <img src={`${IMAGE_PATH}/misc/Divider-Horizontal-Long.png`} alt="Divider Horiztonal Long" className="full" />
                    <div className="flex-row-between gap full">
                        <div className="flex-row-left one-third">
                            <p>Widget Status:</p>
                        </div>
                        <div className="flex-row-right two-thirds">
                            <Select
                                value={showDiscount}
                                className="full"
                                onChange={(e) => handleShowDiscountChange(e)}
                            >
                                <MenuItem value={true}>Active, Displaying Discounts</MenuItem>
                                <MenuItem value={false}>Inactive, Not Displaying Discounts</MenuItem>
                            </Select>
                        </div>
                    </div>
                    <div className="flex-row-between gap full">
                        <div className="flex-row-left one-third">
                            <p>Discount Offer (%):</p>
                        </div>
                        <div className="flex-row-right gap two-thirds">
                            <TextField InputProps={{ inputProps: { min: 0, max: 40 } }} variant="outlined" type="number" value={maxDiscount} className="full"
                                onKeyPress={(e) => {
                                    if (e?.key === "-" || e?.key === "+") {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={handleMaxDiscountChange} />
                        </div>
                    </div>
                    <div className="flex-row-between gap full">
                        <div className="flex-row-left one-third">
                            <p>Show Discounts to Logged In Users:</p>
                        </div>
                        <div className="flex-row-right two-thirds">
                            <Select
                                value={showDiscountLoggedIn}
                                className="full"
                                onChange={(e) => handleShowDiscountLoggedInChange(e)}
                            >
                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </Select>
                        </div>
                    </div>
                    <div className="flex-row-between gap full">
                        <div className="flex-row-left one-third">
                            <p>Vandra's Commission (%):</p>
                        </div>
                        <div className="flex-row-right two-thirds">
                            <TextField variant="outlined" type="text" value={commissionRate} disabled className="full" />
                        </div>
                    </div>
                    <div className="flex-row-right gap full">
                        <Button variant="outlined" style={{ display: isDisabled ? "none" : "", border: "2px solid #856CF8", color: "#856CF8" }} onClick={handleCancelButtonClick} aria-label="Cancel">Cancel</Button>
                        <Button variant="contained" style={{ color: "#fff", background: "#856CF8", opacity: isDisabled ? "0.5" : "" }} onClick={handleSaveAllChangesButtonClick} disabled={isDisabled} aria-label="Save All Changes">Save All Changes</Button>
                    </div>
                    {excludeTags > 0 &&
                        <>
                            <img src={`${IMAGE_PATH}/misc/Divider-Horizontal-Long.png`} alt="Divider Horiztonal Long" className="full" />
                            <div className="flex-row-between full">
                                <h3>How To Exclude Specific Products From Discount Eligibility</h3>
                                <a style={{ textDecoration: "none", color: "#000" }} href={`https://admin.shopify.com/store/${shopUrl}/products?selectedView=all`} target="_blank" rel="noreferrer">
                                    <div className="flex-row-center gap">
                                        <p style={{ whiteSpace: "nowrap" }}><b>Exclude Products</b></p>
                                        <img src={`${IMAGE_PATH}/discount/Discount-View-Store.png`} alt="Discount View Store" />
                                    </div>
                                </a>
                            </div>
                            <p style={{ color: "#6F6F6F" }}>By clicking the “Exclude Products” link, you'll be sent to your product catalog. While there, you can tag products with <b>no-discount</b> to exclude them from discount eligibility. <a href={`https://admin.shopify.com/store/${shopUrl}/products?selectedView=all&tag=no-discount`} target="_blank" rel="noreferrer" className="link">View currently excluded products.</a></p>
                            <iframe title="loom" src="https://www.loom.com/embed/b2fc6446fbbc4b2fb082b1ca88f1fc89" frameBorder="0" webkitAllowFullScreen mozAllowFullScreen allowFullScreen style={{ height: "500px", borderRadius: "1rem" }} className="full">
                            </iframe>
                        </>
                    }
                </div>
            }
        </div>
    );
}

export default Discount;