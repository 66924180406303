export const formatInteger = (integer) => {
    const label = new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }).format(integer);
    return label;
};

export const formatPercentage = (percentage) => {
    const label = new Intl.NumberFormat("en-US", {
        style: "percent",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(percentage);
    return label;
};

export const formatCurrency = (currency) => {
    const label = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
    }).format(currency);
    return label;
};

export const formatUTCDate = (datetime) => {
    const datetimeArray = datetime.split(" ");
    const month = datetimeArray[2];
    const date = datetimeArray[1];
    const year = datetimeArray[3];
    const label = `${month} ${date}, ${year}`;
    return label;
};