import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "react-toastify/dist/ReactToastify.css";
import "./styles/index.css";
import axios from 'axios';

const REACT_APP_ENV = process.env.REACT_APP_ENV;
if (REACT_APP_ENV==="local") {
    axios.defaults.baseURL = 'http://localhost:8080';
} else if (REACT_APP_ENV==="development") {
    axios.defaults.baseURL = 'https://staging.vandra.ai';
} else if (REACT_APP_ENV==="production") {
    axios.defaults.baseURL = 'https://app.vandra.ai';
}


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>
);