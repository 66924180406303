import React, { useState } from "react";
import { Card, TextField, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import axios from "axios";
import { validateText } from "../../helpers/inputValidation";

const DEFAULT_NAME = ""
const DEFAULT_EMAIL = "";
const DEFAULT_MESSAGE = "";
const DEFAULT_SUCCESS = false;

const PARAMS = window.location.search;

const Contact = () => {
    const [name, setName] = useState(DEFAULT_NAME);
    const [email, setEmail] = useState(DEFAULT_EMAIL);
    const [message, setMessage] = useState(DEFAULT_MESSAGE);
    const [success, setSuccess] = useState(DEFAULT_SUCCESS);

    const handleSendMessageButttonClick = async () => {
        try {
            if (!validateText(name)) {
                toast.error("Error: Please enter a name.");
                return;
            }
            if (!validateText(email)) {
                toast.error("Error: Please enter an email.");
                return;
            }
            if (!validateText(message)) {
                toast.error("Error: Please enter a message.");
                return;
            }
            const form = new FormData();
            form.append("name", name);
            form.append("email", email);
            form.append("message", message);
            const contact = await axios.post("/react/merchant_contact" + PARAMS, form);
            if (contact.status === 200) {
                toast.success("Sent");
                setSuccess(true);
            } else {
                throw new Error(contact.status);
            }
        } catch (err) {
            toast.error("Error");
            console.error(err);
        }
    };

    return (
        <div className="flex-col-center">
            <div className="content flex-col-left full gap card">
                <h1>Get in touch with us</h1>
                <p>What do you need help with?</p>
                {success ?
                    <>
                        <p>Thank you. We've received your message and will follow up as soon as possible.</p>
                    </> :
                    <>
                        <TextField label="Full Name" variant="outlined" value={name} className="full" onChange={(e) => setName(e.target.value)} />
                        <TextField label="Email" variant="outlined" value={email} className="full" onChange={(e) => setEmail(e.target.value)} />
                        <TextField label="Message" variant="outlined" multiline value={message} className="full" onChange={(e) => setMessage(e.target.value)} />
                        <Button variant="contained" style={{ background: "#856CF8" }} className="full" onClick={handleSendMessageButttonClick} aria-label="Send Message">Send Message</Button>
                    </>
                }
            </div>
        </div>
    );
}

export default Contact;

/* 
Use: Chat and Call options
Date: 05/01/2023
*/

{/*
<CardRounded className="flex-row-center flex-col-center-mobile gap full card">
    <div className="flex-col-center one-half full-mobile">
        <h3>Help & Support</h3>
        <p>We're here to help with any questions regarding the app.</p>
        <Button variant="outlined" style={{ border: "2px solid #856CF8", color: "#856CF8" }} className="two-thirds full-mobile">Chat with us</Button>
        <div className="flex-row-center gap full">
            <img src={`${IMAGE_PATH}/misc/Divider-Horizontal-Short.png`} alt="Divider Horizontal Short" />
            <p>or</p>
            <img src={`${IMAGE_PATH}/misc/Divider-Horizontal-Short.png`} alt="Divider Horizontal Short" />
        </div>
        <Button variant="outlined" style={{ border: "2px solid #856CF8", color: "#856CF8" }} className="two-thirds full-mobile">Call us</Button>
    </div>
    <img src={`${IMAGE_PATH}/misc/Divider-Vertical-Long.png`} alt="Divider Vertical Long" className="hidden-mobile" />
    <img src={`${IMAGE_PATH}/misc/Divider-Horizontal.png`} alt="Divider Horizontal" className="hidden show-mobile" />
    <div className="flex-col-center gap one-half full-mobile">
        {success ?
            <>
                <p>Thank you. We've received your message and will follow up as soon as possible.</p>
            </> :
            <>
                <TextField label="Full Name" variant="outlined" value={name} className="two-thirds full-mobile" onChange={(e) => setName(e.target.value)} />
                <TextField label="Email" variant="outlined" value={email} className="two-thirds full-mobile" onChange={(e) => setEmail(e.target.value)} />
                <TextField label="Message" variant="outlined" multiline value={message} className="two-thirds full-mobile" onChange={(e) => setMessage(e.target.value)} />
                <Button variant="contained" style={{ background: "#856CF8" }} className="two-thirds full-mobile" onClick={handleSendMessageButttonClick}>Send Message</Button>
            </>
        }
    </div>
</CardRounded>
*/}