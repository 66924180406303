import React, { useState, useEffect } from "react";
import { Card, Select, MenuItem, Button, Tooltip, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import axios from "axios";
import Loader from "../UI/Loader";
import { UTC_EASTERN_TIME_ZONE_OFFSET } from "../../helpers/inputValidation";
import { formatInteger, formatPercentage, formatCurrency, formatUTCDate } from "../../helpers/formatter";

const IMAGE_PATH =  "/img";

const DEFAULT_OVERVIEW_STATS = {};
const DEFAULT_SEGMENTATION_STATS = {};
const DEFAULT_DAILY_STATS = [];
const DEFAULT_TEST_STORE = "ollypbc.myshopify.com";
const DEFAULT_IS_TEST_MODE = undefined;
const DEFAULT_IS_PAGE_LOADING = false;
const DEFAULT_IS_ANALYTICS_LOADING = false;
const DEFAULT_IS_EXPORT_LOADING = false;

const PARAMS = window.location.search;
const MIN_DATE_TIMESTAMP = 1688399197;

const CardCustom = styled(Card)(({ theme }) => ({
    border: "1px solid #E0E0E0",
    borderRadius: "0.5rem",
    boxShadow: "none"
}));

const Analytics = () => {
    let today = new Date();
    const todayUtc = today.getTime() + (today.getTimezoneOffset() * 60000);
    today = new Date(new Date(todayUtc + (3600000 * UTC_EASTERN_TIME_ZONE_OFFSET)).setUTCHours(Math.abs(UTC_EASTERN_TIME_ZONE_OFFSET), 0, 0, 0));
    let yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    let lastWeek = new Date(today);
    lastWeek.setDate(today.getDate() - 7);
    let minDate = new Date(MIN_DATE_TIMESTAMP * 1000);
    const [endDate, setEndDate] = useState(dayjs(yesterday));
    const [startDate, setStartDate] = useState(dayjs(lastWeek));
    const [overviewStats, setOverviewStats] = useState(DEFAULT_OVERVIEW_STATS);
    const [segmentationStats, setSegmentationStats] = useState(DEFAULT_SEGMENTATION_STATS);
    const [dailyStats, setDailyStats] = useState(DEFAULT_DAILY_STATS);
    const [testStore, setTestStore] = useState(DEFAULT_TEST_STORE);
    const [isTestMode, setIsTestMode] = useState(DEFAULT_IS_TEST_MODE);
    const [isPageLoading, setIsPageLoading] = useState(DEFAULT_IS_PAGE_LOADING);
    const [isAnalyticsLoading, setIsAnalyticsLoading] = useState(DEFAULT_IS_ANALYTICS_LOADING);
    const [isExportLoading, setIsExportLoading] = useState(DEFAULT_IS_EXPORT_LOADING);
    const [testStores, setTestStores] = useState([]);

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (startDate && endDate && isTestMode !== undefined) {
            if (isTestMode) {
                getTestAnalytics();
                fetchTestStores();
            } else {
                getAnalytics();
            }
        }
    }, [startDate, endDate, isTestMode, testStore]);

    const init = async () => {
        setIsPageLoading(true);
        const testModeVerification = await getTestModeVerification();
        setIsTestMode(testModeVerification);
        setIsPageLoading(DEFAULT_IS_PAGE_LOADING);
    };

    const fetchTestStores = async () => {
        if (true) {
            axios.get("/react/merchant_analytics_test_stores")
                .then((response) => {
                    // handle success
                    setTestStores(response.data.test_stores);
                })
                .catch((error) => {
                    // handle error
                    toast.error("Error");
                })
        }   
        
    };

    const getTestModeVerification = async () => {
        try {
            const testModeVerification = await axios.get("/react/merchant_analytics_test_mode_verification" + PARAMS);
            if (testModeVerification.status === 200) {
                if (testModeVerification.data?.test_mode !== undefined) {
                    return testModeVerification.data.test_mode;
                }
            } else {
                throw new Error(testModeVerification.status);
            }
        } catch (err) {
            toast.error("Error");
            console.error(err);
        }
    };

    const getAnalytics = async () => {
        setIsAnalyticsLoading(true);
        try {
            const zero = new Date(0);
            let today = new Date();
            const todayUtc = today.getTime() + (today.getTimezoneOffset() * 60000);
            today = new Date(new Date(todayUtc + (3600000 * UTC_EASTERN_TIME_ZONE_OFFSET)).setUTCHours(Math.abs(UTC_EASTERN_TIME_ZONE_OFFSET), 0, 0, 0));
            const now = new Date();
            const minTime = startDate ? startDate.$d : zero;
            const maxTime = endDate ? endDate.$d : now;
            const analytics = await axios.get("/react/merchant_analytics" + PARAMS, {
                params: {
                    min_time: minTime,
                    max_time: maxTime
                }
            });
            if (analytics.status === 200) {
                if (analytics.data?.overview_stats !== undefined) {
                    const overviewStats = analytics.data.overview_stats;
                    setOverviewStats(overviewStats);
                }
                if (analytics.data?.segmentation_stats !== undefined) {
                    const segmentationStats = analytics.data.segmentation_stats;
                    setSegmentationStats(segmentationStats);
                }
                if (analytics.data?.daily_stats !== undefined) {
                    let dailyStats = analytics.data.daily_stats;
                    if (dailyStats !== undefined && dailyStats.length > 0) {
                        setDailyStats(dailyStats);
                    }
                }
            } else {
                throw new Error(analytics.status);
            }
        } catch (err) {
            toast.error("Error");
            console.error(err);
        } finally {
            setIsAnalyticsLoading(DEFAULT_IS_ANALYTICS_LOADING);
        }
    };

    const getTestAnalytics = async () => {
        setIsAnalyticsLoading(true);
        try {
            const zero = new Date(0);
            let today = new Date();
            const todayUtc = today.getTime() + (today.getTimezoneOffset() * 60000);
            today = new Date(new Date(todayUtc + (3600000 * UTC_EASTERN_TIME_ZONE_OFFSET)).setUTCHours(Math.abs(UTC_EASTERN_TIME_ZONE_OFFSET), 0, 0, 0));
            const now = new Date();
            const minTime = startDate ? startDate.$d : zero;
            const maxTime = endDate ? endDate.$d : now;
            const analytics = await axios.get("/react/merchant_analytics" + PARAMS, {
                params: {
                    test_store: testStore,
                    min_time: minTime,
                    max_time: maxTime
                }
            });
            if (analytics.status === 200) {
                if (analytics.data?.overview_stats !== undefined) {
                    const overviewStats = analytics.data.overview_stats;
                    setOverviewStats(overviewStats);
                }
                if (analytics.data?.segmentation_stats !== undefined) {
                    const segmentationStats = analytics.data.segmentation_stats;
                    setSegmentationStats(segmentationStats);
                }
                if (analytics.data?.daily_stats !== undefined) {
                    let dailyStats = analytics.data.daily_stats;
                    if (dailyStats !== undefined && dailyStats.length > 0) {
                        setDailyStats(dailyStats);
                    }
                }
            } else {
                throw new Error(analytics.status);
            }
        } catch (err) {
            toast.error("Error");
            console.error(err);
        } finally {
            setIsAnalyticsLoading(DEFAULT_IS_ANALYTICS_LOADING);
        }
    };


    const handleExportClick = async () => {
        setIsExportLoading(true);
        try {
            const zero = new Date(0);
            let today = new Date();
            const todayUtc = today.getTime() + (today.getTimezoneOffset() * 60000);
            today = new Date(new Date(todayUtc + (3600000 * UTC_EASTERN_TIME_ZONE_OFFSET)).setUTCHours(Math.abs(UTC_EASTERN_TIME_ZONE_OFFSET), 0, 0, 0));
            const now = new Date();
            const minTime = startDate ? startDate.$d : zero;
            const maxTime = endDate ? endDate.$d : now;
            const differenceSeconds = Math.abs(maxTime - minTime) / 1000;
            // 30 day limit for CSV export, otherwise email export
            if (differenceSeconds > 2592000) {
                toast.warning("Since this is a larger data set, we will send the exported data to your account's inbox.");
            } else {
                toast.warning("Please note that large exports may take some time.");
            }
            const analytics = await axios.get("/react/merchant_new_analytics_export" + PARAMS, {
                params: {
                    min_time: minTime,
                    max_time: maxTime
                }
            });
            if (analytics.status === 200) {
                if (analytics.data?.csv !== undefined) {
                    const csv = analytics.data.csv;
                    const encodedUri = URL.createObjectURL(
                        new Blob([csv], { type: "text/csv;charset=utf-8;" })
                    );
                    const link = document.createElement("a");
                    link.setAttribute("href", encodedUri);
                    link.setAttribute("download", `Vandra.csv`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    toast.success("Successful download.");
                } else if (analytics.data?.email !== undefined) {
                    const email = analytics.data?.email;
                    if (email === "error") {
                        toast.error("Please register an email with your Shopify store first.");
                    } else {
                        toast.success(`Please expect an email from mail@appmail.vandra.ai sent to ${email}.`);
                    }
                }
            } else {
                throw new Error(analytics.status);
            }
        } catch (err) {
            toast.error("Error");
            console.error(err);
        } finally {
            setIsExportLoading(DEFAULT_IS_EXPORT_LOADING);
        }
    };

    return (
        <div className="flex-col-center">
            {isPageLoading ?
                <div className="content flex-col-center gap full">
                    <Loader />
                </div> :
                <div className="content flex-col-left gap full">
                    <h1>Analytics</h1>
                    {isTestMode ?
                        <Select
                            value={testStore}
                            className="full"
                            onChange={(e) => setTestStore(e.target.value)}
                        >
                            {testStores.map((testStoreEntry) => (
                                <MenuItem key={testStoreEntry.name} value={testStoreEntry.url}>{testStoreEntry.name}</MenuItem>
                            ))}
                        </Select> :
                        <></>
                    }
                    <div className="flex-row-between gap full">
                        <div className="flex-row-left gap">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Start Date"
                                    minDate={dayjs(minDate)}
                                    maxDate={dayjs(endDate).subtract(1, "day")}
                                    value={startDate}
                                    onChange={(startDate) => setStartDate(startDate)}
                                />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="End Date"
                                    minDate={dayjs(startDate).add(1, "day")}
                                    disableFuture={true}
                                    value={endDate}
                                    onChange={(endDate) => setEndDate(endDate)}
                                />
                            </LocalizationProvider>
                        </div>
                        {isExportLoading ?
                            <Loader /> :
                            <Button variant="outlined" startIcon={<img src={`${IMAGE_PATH}/analytics/Export.png`} alt="Export" />} style={{ border: "1px solid black", color: "#000" }} onClick={handleExportClick}>Export</Button>
                        }
                    </div>
                    <div className="flex-row-between gap full">
                        <CardCustom className="flex-col-left full card-xs">
                            <div className="flex-row-between gap full">
                                <p style={{ color: "#97999F" }}>Vandra Nudged Sales</p>
                                <Tooltip placement="top" title="Vandra-Nudged Sales reflects the total sales volume of all orders that have used a Vandra discount code. For each order, the subtotal is used. This value is post-discount and pre-taxes/shipping" arrow>
                                    <IconButton>
                                        <InfoOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div className="flex-col-left gap full">
                                {isAnalyticsLoading ?
                                    <Loader /> :
                                    <h1 style={{ margin: 0 }}>{overviewStats?.vandra_sales !== undefined ? `${formatCurrency(overviewStats.vandra_sales)}` : "N/A"}</h1>
                                }
                            </div>
                        </CardCustom>
                        <CardCustom className="flex-col-left full card-xs">
                            <div className="flex-row-between gap full">
                                <p style={{ color: "#97999F" }}>Vandra Effective Lift</p>
                                <Tooltip placement="top" title="Vandra Lift = Vandra Sales / (Total Audience Revenue - Vandra Sales)" arrow>
                                    <IconButton>
                                        <InfoOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div className="flex-col-left gap full">
                                {isAnalyticsLoading ?
                                    <Loader /> :
                                    <h1 style={{ margin: 0 }}>{overviewStats?.vandra_lift !== undefined ? `${formatPercentage(overviewStats.vandra_lift)}` : "N/A"}</h1>
                                }
                            </div>
                        </CardCustom>
                        <CardCustom className="flex-col-left full card-xs">
                            <div className="flex-row-between gap full">
                                <p style={{ color: "#97999F" }}>Vandra Nudged Orders</p>
                                <Tooltip placement="top" title="Total number of orders made with a Vandra discount within the selected date range" arrow>
                                    <IconButton>
                                        <InfoOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div className="flex-col-left gap full">
                                {isAnalyticsLoading ?
                                    <Loader /> :
                                    <h1 style={{ margin: 0 }}>{overviewStats?.vandra_orders !== undefined ? `${formatInteger(overviewStats.vandra_orders)}` : "N/A"}</h1>
                                }
                            </div>
                        </CardCustom>
                    </div>
                    <CardCustom className="flex-col-left gap full card" style={{ padding: 0 }}>
                        <h2 style={{ padding: "0px 2rem 0px 2rem" }}>Segmentation Effectiveness</h2>
                        <div className="flex-row-between gap-lg full" style={{ position: "relative", borderTop: "1px solid #F6F6F6", padding: "0px 3rem 0px 3rem" }}>
                            <img src={`${IMAGE_PATH}/analytics/Segmentation-Total.png`} alt="Segmentation Total" style={{ position: "absolute", top: 0, left: 0 }} />
                            <div className="flex-col-left one-third" style={{ padding: 0 }}>
                                <h3>Total Audience</h3>
                                <p style={{ color: "#97999F" }}>All users <b>that reached the decision point</b>, including both high-intent non-recipients and low-intent discount recipients of Vandra nudges</p>
                            </div>
                            <div className="flex-row-between gap two-thirds">
                                <div className="flex-col-left full">
                                    <small style={{ whiteSpace: "nowrap", color: "#97999F" }}>REVENUE</small>
                                    {isAnalyticsLoading ?
                                        <Loader /> :
                                        <h3>{segmentationStats?.vandra_total_revenue !== undefined ? `${formatCurrency(segmentationStats.vandra_total_revenue)}` : "N/A"}</h3>
                                    }
                                </div>
                                <div className="flex-col-left full">
                                    <small style={{ whiteSpace: "nowrap", color: "#97999F" }}>CONVERSION RATE</small>
                                    {isAnalyticsLoading ?
                                        <Loader /> :
                                        <h3>{segmentationStats?.vandra_total_conversion_rate !== undefined ? `${formatPercentage(segmentationStats.vandra_total_conversion_rate)}` : "N/A"}</h3>
                                    }
                                </div>
                                <div className="flex-col-left full">
                                    <small style={{ whiteSpace: "nowrap", color: "#97999F" }}>SESSIONS</small>
                                    {isAnalyticsLoading ?
                                        <Loader /> :
                                        <h3>{segmentationStats?.vandra_total_sessions !== undefined ? `${formatInteger(segmentationStats.vandra_total_sessions)}` : "N/A"}</h3>
                                    }
                                </div>
                                <div className="flex-col-left full">
                                    <small style={{ whiteSpace: "nowrap", color: "#97999F" }}>VANDRA ORDERS</small>
                                    {isAnalyticsLoading ?
                                        <Loader /> :
                                        <h3>{segmentationStats?.vandra_total_orders !== undefined ? `${formatInteger(segmentationStats.vandra_total_orders)}` : "N/A"}</h3>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="flex-row-between gap-lg full" style={{ position: "relative", borderTop: "1px solid #F6F6F6", padding: "0px 3rem 0px 3rem" }}>
                            <img src={`${IMAGE_PATH}/analytics/Segmentation-Protected.png`} alt="Segmentation Protected" style={{ position: "absolute", top: 0, left: 0 }} />
                            <div className="flex-col-left one-third">
                                <h3>Protected Audience</h3>
                                <p style={{ color: "#97999F" }}>High-intent users who proceeded through the purchase process without receiving Vandra discounts</p>
                            </div>
                            <div className="flex-row-between gap two-thirds">
                                <div className="flex-col-left full">
                                    <small style={{ whiteSpace: "nowrap", color: "#97999F" }}>REVENUE</small>
                                    {isAnalyticsLoading ?
                                        <Loader /> :
                                        <h3>{segmentationStats?.vandra_protected_revenue !== undefined ? `${formatCurrency(segmentationStats.vandra_protected_revenue)}` : "N/A"}</h3>
                                    }
                                </div>
                                <div className="flex-col-left full">
                                    <small style={{ whiteSpace: "nowrap", color: "#97999F" }}>CONVERSION RATE</small>
                                    {isAnalyticsLoading ?
                                        <Loader /> :
                                        <h3>{segmentationStats?.vandra_protected_conversion_rate !== undefined ? `${formatPercentage(segmentationStats.vandra_protected_conversion_rate)}` : "N/A"}</h3>
                                    }
                                </div>
                                <div className="flex-col-left full">
                                    <small style={{ whiteSpace: "nowrap", color: "#97999F" }}>SESSIONS</small>
                                    {isAnalyticsLoading ?
                                        <Loader /> :
                                        <h3>{segmentationStats?.vandra_protected_sessions !== undefined ? `${formatInteger(segmentationStats.vandra_protected_sessions)}` : "N/A"}</h3>
                                    }
                                </div>
                                <div className="flex-col-left full">
                                    <small style={{ whiteSpace: "nowrap", color: "#97999F" }}>VANDRA ORDERS</small>
                                    <div className="flex-row-left gap-sm">
                                        <img src={`${IMAGE_PATH}/analytics/Segmentation-Lock.png`} alt="Segmentation Lock" />
                                        <h3>N/A</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex-row-between gap-lg full" style={{ position: "relative", borderTop: "1px solid #F6F6F6", padding: "0px 3rem 0px 3rem" }}>
                            <img src={`${IMAGE_PATH}/analytics/Segmentation-Actionable.png`} alt="Segmentation Actionable" style={{ position: "absolute", top: 0, left: 0 }} />
                            <div className="flex-col-left one-third">
                                <h3>Actionable Audience</h3>
                                <p style={{ color: "#97999F" }}>Low-intent users offered targeted Vandra discounts in response to signs of purchase hesitancy</p>
                            </div>
                            <div className="flex-row-between gap two-thirds">
                                <div className="flex-col-left full">
                                    <small style={{ whiteSpace: "nowrap", color: "#97999F" }}>REVENUE</small>
                                    {isAnalyticsLoading ?
                                        <Loader /> :
                                        <h3>{segmentationStats?.vandra_actionable_revenue !== undefined ? `${formatCurrency(segmentationStats.vandra_actionable_revenue)}` : "N/A"}</h3>
                                    }
                                </div>
                                <div className="flex-col-left full">
                                    <small style={{ whiteSpace: "nowrap", color: "#97999F" }}>CONVERSION RATE</small>
                                    {isAnalyticsLoading ?
                                        <Loader /> :
                                        <h3>{segmentationStats?.vandra_actionable_conversion_rate !== undefined ? `${formatPercentage(segmentationStats.vandra_actionable_conversion_rate)}` : "N/A"}</h3>
                                    }
                                </div>
                                <div className="flex-col-left full">
                                    <small style={{ whiteSpace: "nowrap", color: "#97999F" }}>SESSIONS</small>
                                    {isAnalyticsLoading ?
                                        <Loader /> :
                                        <h3>{segmentationStats?.vandra_actionable_sessions !== undefined ? `${formatInteger(segmentationStats.vandra_actionable_sessions)}` : "N/A"}</h3>
                                    }
                                </div>
                                <div className="flex-col-left full">
                                    <small style={{ whiteSpace: "nowrap", color: "#97999F" }}>VANDRA ORDERS</small>
                                    {isAnalyticsLoading ?
                                        <Loader /> :
                                        <h3>{segmentationStats?.vandra_actionable_orders !== undefined ? `${formatInteger(segmentationStats.vandra_actionable_orders)}` : "N/A"}</h3>
                                    }
                                </div>
                            </div>
                        </div>
                    </CardCustom>
                    <CardCustom className="flex-col-left gap full card">
                        <h2>Vandra's Performance Boost</h2>
                        {isAnalyticsLoading ?
                            <Loader /> :
                            <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><u>Date</u></TableCell>
                                            <TableCell align="right">
                                                <Tooltip placement="top" title="The Revenue reflected here indicates your Vandra Nudged Sales for a specific date within the currently selected date range." arrow>
                                                    <u>Revenue</u>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Tooltip placement="top" title="Total number of orders made with a Vandra discount." arrow>
                                                    <u>Vandra Orders</u>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Tooltip placement="top" title="Total number of online store sessions that Vandra's AI deemed to be low intent/unlikely to convert. Low intent sessions are usually a minority half of your total online store sessions, which is a metric that can be found on your Shopify Analytics Dashboard." arrow>
                                                    <u>Actionable Sessions</u>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Tooltip placement="top" title="The percentage of your online shoppers who were shown a Vandra discount." arrow>
                                                    <u>Show Rate</u>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Tooltip placement="top" title="The percentage of discounts that were shown and then applied." arrow>
                                                    <u>Show-to-Apply Rate</u>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Tooltip placement="top" title="Percentage of low intent sessions that resulted in online store orders." arrow>
                                                    <u>Apply-to-Convert Rate</u>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Tooltip placement="top" title="The total dollar value that Vandra charged in commission for orders on this date." arrow>
                                                    <u>Commission</u>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {dailyStats.sort((a, b) => new Date(b.vandra_date_daily) - new Date(a.vandra_date_daily)).map((dailyStat) => (
                                            <TableRow key={dailyStat.vandra_date_daily}>
                                                <TableCell>{dailyStat?.vandra_date_daily ? formatUTCDate(new Date(dailyStat.vandra_date_daily).toUTCString()) : "N/A"}</TableCell>
                                                <TableCell align="right">{dailyStat?.vandra_revenue_daily !== null ? formatCurrency(dailyStat.vandra_revenue_daily) : "N/A"}</TableCell>
                                                <TableCell align="right">{dailyStat?.vandra_orders_daily !== null ? formatInteger(dailyStat.vandra_orders_daily) : "N/A"}</TableCell>
                                                <TableCell align="right">{dailyStat?.vandra_actionable_sessions_daily !== null ? formatInteger(dailyStat.vandra_actionable_sessions_daily) : "N/A"}</TableCell>
                                                <TableCell align="right">{dailyStat?.vandra_show_rate_daily !== null ? formatPercentage(dailyStat.vandra_show_rate_daily) : "N/A"}</TableCell>
                                                <TableCell align="right">{dailyStat?.vandra_show_to_apply_rate_daily !== null ? formatPercentage(dailyStat.vandra_show_to_apply_rate_daily) : "N/A"}</TableCell>
                                                <TableCell align="right">{dailyStat?.vandra_apply_to_convert_rate_daily !== null ? formatPercentage(dailyStat.vandra_apply_to_convert_rate_daily) : "N/A"}</TableCell>
                                                <TableCell align="right">{dailyStat?.vandra_commission_daily !== null ? formatCurrency(dailyStat.vandra_commission_daily) : "N/A"}</TableCell>
                                            </TableRow>)
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }
                    </CardCustom>
                </div >
            }
        </div >
    );
}

export default Analytics;