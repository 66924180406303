import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import createApp from "@shopify/app-bridge";
import { Redirect } from "@shopify/app-bridge/actions";
import { Modal } from "@shopify/app-bridge-react";
import { Card } from "@mui/material";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import axios from "axios";
import SelectPeriod from "../UI/SelectPeriod";
import Loader from "../UI/Loader";
import { calculateMinMaxTime } from "../../helpers/inputValidation";

const REACT_APP_ENV = process.env.REACT_APP_ENV;
const IMAGE_PATH =  "/img";

const DEFAULT_SHOP_NAME = "";
const DEFAULT_PERIOD = "total";
const DEFAULT_SALES = 0;
const DEFAULT_SESSION_COUNT = 0;
const DEFAULT_SHOWN_COUNT = 0;
const DEFAULT_ORDER_COUNT = 0;
const DEFAULT_SHOW_DISCOUNT = false;
const DEFAULT_VANDRA_ADMIN_SHOW_DISCOUNT = false;
const DEFAULT_FIRST_VISIT = false;
const DEFAULT_REDIRECT_TO_ONBOARDING = false;
const DEFAULT_IS_PAGE_LOADING = false;
const DEFAULT_IS_SALES_LOADING = false;

const DEFAULT_WELCOME_MESSAGE = "That's it. You're ready to go. We are now actively collecting data, training our AI algorithm for your brand. It may take a couple days of training before we start surfacing discounts. The discount codes we use will all start with vandra to help us track conversions and avoid confusion with any existing discount codes. Thanks for partnering with us to squeeze more sales out of your existing traffic."

const PARAMS = window.location.search;

const CardCustom = styled(Card)(({ theme }) => ({
    border: "1px solid #E0E0E0",
    borderRadius: "1rem",
    boxShadow: "none"
}));

const Overview = (props) => {
    const [shopName, setShopName] = useState(DEFAULT_SHOP_NAME);
    const [period, setPeriod] = useState(DEFAULT_PERIOD);
    const [sales, setSales] = useState(DEFAULT_SALES);
    const [sessionCount, setSessionCount] = useState(DEFAULT_SESSION_COUNT);
    const [shownCount, setShownCount] = useState(DEFAULT_SHOWN_COUNT);
    const [orderCount, setOrderCount] = useState(DEFAULT_ORDER_COUNT);
    const [showDiscount, setShowDiscount] = useState(DEFAULT_SHOW_DISCOUNT);
    const [vandraAdminShowDiscount, setVandraAdminShowDiscount] = useState(DEFAULT_VANDRA_ADMIN_SHOW_DISCOUNT);
    const [firstVisit, setFirstVisit] = useState(DEFAULT_FIRST_VISIT);
    const [redirectToOnboarding, setRedirectToOnboarding] = useState(DEFAULT_REDIRECT_TO_ONBOARDING);
    const [isPageLoading, setIsPageLoading] = useState(DEFAULT_IS_PAGE_LOADING);
    const [isSalesLoading, setIsSalesLoading] = useState(DEFAULT_IS_SALES_LOADING);
    const navigate = useNavigate();

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        getSales();
    }, [period]);

    useEffect(() => {
        if (redirectToOnboarding) {
            navigate("/onboarding" + PARAMS);
        }
    }, [redirectToOnboarding]);

    const getData = async () => {
        setIsPageLoading(true);
        await getDashboard();
        await getBilling();
        setIsPageLoading(DEFAULT_IS_PAGE_LOADING);
    }

    const getDashboard = async () => {
        try {
            const dashboard = await axios.get("/react/merchant_dashboard" + PARAMS);
            if (dashboard.status === 200) {
                if (dashboard.data?.redirect_url !== undefined) {
                    if (REACT_APP_ENV === "production") {
                        const reactApp = createApp(props.config);
                        const redirect = Redirect.create(reactApp);
                        redirect.dispatch(Redirect.Action.REMOTE, dashboard.data.redirect_url);
                    }
                } else {
                    if (dashboard.data?.shop_name !== undefined) {
                        setShopName(dashboard.data.shop_name);
                    }
                    if (dashboard.data?.session_count !== undefined) {
                        setSessionCount(dashboard.data.session_count);
                    }
                    if (dashboard.data?.shown_count !== undefined) {
                        setShownCount(dashboard.data.shown_count);
                    }
                    if (dashboard.data?.order_count !== undefined) {
                        setOrderCount(dashboard.data.order_count);
                    }
                    if (dashboard.data?.show_discount !== undefined) {
                        setShowDiscount(dashboard.data.show_discount);
                    }
                    if (dashboard.data?.vandra_admin_show_discount !== undefined) {
                        setVandraAdminShowDiscount(dashboard.data.vandra_admin_show_discount);
                    }
                    if (dashboard.data?.first_visit !== undefined) {
                        setFirstVisit(dashboard.data.first_visit);
                    }
                    if (dashboard.data?.redirect_to_onboarding !== undefined) {
                        setRedirectToOnboarding(dashboard.data.redirect_to_onboarding);
                    }
                }
            } else {
                throw new Error(dashboard.status);
            }
        } catch (err) {
            toast.error("Error");
            console.error(err);
        }
    };

    const getBilling = async () => {
        try {
            const billing = await axios.get("/react/merchant_check_billing" + PARAMS);
            if (billing.status === 200) {
                if (billing.data?.redirect_url !== undefined) {
                    if (REACT_APP_ENV === "production") {
                        const reactApp = createApp(props.config);
                        const redirect = Redirect.create(reactApp);
                        redirect.dispatch(Redirect.Action.REMOTE, billing.data.redirect_url);
                    }
                }
            } else {
                throw new Error(billing.status);
            }
        } catch (err) {
            toast.error("Error");
            console.error(err);
        }
    };

    const getSales = async () => {
        setIsSalesLoading(true);
        try {
            const { minTime, maxTime } = calculateMinMaxTime(period);
            const sales = await axios.get("/react/merchant_dashboard_sales" + PARAMS, {
                params: {
                    min_time: minTime,
                    max_time: maxTime
                }
            });
            if (sales.status === 200) {
                if (sales.data?.sales !== undefined) {
                    const sales_formatted = new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }).format(sales.data.sales);
                    setSales(sales_formatted);
                }
            }
        } catch (err) {
            console.error(err);
        }
        setIsSalesLoading(DEFAULT_IS_SALES_LOADING);
    };

    return (
        <div className="flex-col-center">
            {isPageLoading ?
                <div className="content flex-col-center gap full">
                    <Loader />
                </div> :
                <div className="content flex-col-center gap full">
                    {REACT_APP_ENV === "production" && <Modal title="Welcome to Vandra" message={DEFAULT_WELCOME_MESSAGE} open={firstVisit} />}
                    <CardCustom sx={{ position: "relative", zIndex: 2, background: "#F8F6FF" }} className="flex-col-center full card">
                        <img style={{ position: "absolute", bottom: -100, left: -100, zIndex: 1 }} src={`${IMAGE_PATH}/overview/Overview-Banner.png`} alt="Overview Banner" />
                        <p style={{ zIndex: 2, margin: "0rem" }}>Welcome,</p>
                        <h1 style={{ zIndex: 2, margin: "0.5rem" }}>{shopName}</h1>
                        <CardCustom sx={{ zIndex: 2, padding: "1rem 2rem 1rem 2rem", background: "#856CF8" }} className="flex-row-center flex-col-center-mobile gap card">
                            <div id="selectSales" className="flex-row-center gap full">
                                <SelectPeriod period={period} setPeriod={setPeriod} color="white" />
                                <img src={`${IMAGE_PATH}/misc/Divider-Vertical-Short.png`} alt="Divider Vertical Short" className="hidden-mobile" />
                            </div>
                            <CardCustom style={{ zIndex: 2, padding: "0rem 2rem 0rem 2rem" }} className="flex-col-center full">
                                {isSalesLoading ? <Loader /> : <h3 style={{ width: "100px", textAlign: "center" }}>{sales}</h3>}
                            </CardCustom>
                            <p style={{ color: "#fff", whiteSpace: "nowrap" }}>Additional Sales</p>
                        </CardCustom>
                        <img style={{ position: "absolute", top: -100, right: -100, zIndex: 1 }} src={`${IMAGE_PATH}/overview/Overview-Banner.png`} alt="Overview Banner" />
                    </CardCustom>
                    {!showDiscount && <p>Discounts are currently disabled. You can turn them on in <Link to={"/discount" + PARAMS} style={{ color: "#856CF8", textDecoration: "none" }}>discount settings</Link>.</p>}
                    {!vandraAdminShowDiscount && <p>Discounts are currently disabled. Speak with your Vandra account rep to enable them.</p>}
                </div>
            }
        </div>
    );
};

export default Overview;