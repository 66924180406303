import React, { useState, useEffect, useMemo } from "react";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { Provider } from "@shopify/app-bridge-react";
import { Box, Drawer, CssBaseline, AppBar, Toolbar, List, Divider, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Menu, ChevronLeft, ChevronRight } from "@mui/icons-material";
import { styled, useTheme } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import Onboarding from "./components/pages/Onboarding";
import Overview from "./components/pages/Overview";
import Analytics from "./components/pages/Analytics";
import Widget from "./components/pages/Widget";
import Discount from "./components/pages/Discount";
import Contact from "./components/pages/Contact";

const REACT_APP_ENV = process.env.REACT_APP_ENV;
const REACT_APP_CONFIG_API_KEY = process.env.REACT_APP_CONFIG_API_KEY;

const IMAGE_PATH = "/img";

const DEFAULT_PAGE = "Home";
const DEFAULT_OPEN = false;

const CONFIG = {
    apiKey: REACT_APP_CONFIG_API_KEY,
    host: new URLSearchParams(window.location.search).get("host"),
    forceRedirect: false
};

const ROUTER_MAP = {
    "Onboarding": "/onboarding",
    "Overview": "/",
    "Analytics": "/analytics",
    "Widget Settings": "/widget",
    "Discount Settings": "/discount",
    "Contact": "/contact",
    "Settings": "/settings"
}

const DRAWER_WIDTH = 240;

const VandraAppBar = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        marginLeft: `${DRAWER_WIDTH}px`,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
}));

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${DRAWER_WIDTH}px`,
        ...(open && {
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const App = () => {
    const [page, setPage] = useState(DEFAULT_PAGE)
    const [open, setOpen] = useState(DEFAULT_OPEN);
    const location = useLocation();
    const navigate = useNavigate();
    const history = useMemo(
        () => ({ replace: (path) => navigate(path, { replace: true }) }),
        [navigate],
    );
    const appBridgeRouter = useMemo(
        () => ({
            location,
            history,
        }),
        [location, history],
    );
    const theme = useTheme();

    useEffect(() => {
        const path = location.pathname;
        const page = Object.keys(ROUTER_MAP).filter((key) => ROUTER_MAP[key] === path)[0];
        setPage(page);
    }, [location])

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleMenuClick = (text) => {
        navigate(ROUTER_MAP[text]);
    }

    const Vandra = <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <VandraAppBar position="fixed" open={open} sx={{ borderBottom: "1.5px solid #E0E0E0", color: "#000", background: "#fff", boxShadow: "none" }}>
            <Toolbar className="flex-row-center">
                <div className="flex-row-left full">
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: "none" }) }}
                    >
                        <Menu />
                    </IconButton>
                </div>
                <div className="flex-row-center full">
                    <img src={`${IMAGE_PATH}/branding/Vandra-Logo.png`} alt="Vandra Logo" />
                </div>
                <div className="flex-row-right full"></div>
            </Toolbar>
        </VandraAppBar>
        <Drawer
            variant="persistent"
            anchor="left"
            open={open}
            sx={{
                width: DRAWER_WIDTH,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                    width: DRAWER_WIDTH,
                    boxSizing: "border-box",
                },
            }}
        >
            <DrawerHeader>
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === "ltr" ? <ChevronLeft /> : <ChevronRight />}
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List sx={{ padding: "5px" }}>
                {page === "Onboarding" ? ["Onboarding"].map((text, index) => (
                    <ListItem key={text} disablePadding sx={{ borderRadius: "5px", color: text === page ? "#fff" : "#000", background: text === page ? "#856CF8" : "" }} onClick={() => handleMenuClick(text)}>
                        <ListItemButton>
                            <ListItemIcon>
                                <img src={`${IMAGE_PATH}/menu/Menu-Onboarding-White.png`} alt="Menu Onboarding White" />
                            </ListItemIcon>
                            <ListItemText primary={text} sx={{ whiteSpace: "nowrap" }} />
                        </ListItemButton>
                    </ListItem>
                )) : ["Overview", "Analytics", "Widget Settings", "Discount Settings", "Contact"].map((text, index) => (
                    <ListItem key={text} disablePadding sx={{ borderRadius: "5px", color: text === page ? "#fff" : "#000", background: text === page ? "#856CF8" : "" }} onClick={() => handleMenuClick(text)}>
                        <ListItemButton>
                            <ListItemIcon>
                                {index === 0 ? text === page ? <img src={`${IMAGE_PATH}/menu/Menu-Overview-White.png`} alt="Menu Overview White" /> : <img src={`${IMAGE_PATH}/menu/Menu-Overview-Black.png`} alt="Menu Overview Black" /> :
                                    index === 1 ? text === page ? <img src={`${IMAGE_PATH}/menu/Menu-Analytics-White.png`} alt="Menu Analytics White" /> : <img src={`${IMAGE_PATH}/menu/Menu-Analytics-Black.png`} alt="Menu Analytics Black" /> :
                                        index === 2 ? text === page ? <img src={`${IMAGE_PATH}/menu/Menu-Widget-White.png`} alt="Menu Widget White" /> : <img src={`${IMAGE_PATH}/menu/Menu-Widget-Black.png`} alt="Menu Widget Black" /> :
                                            index === 3 ? text === page ? <img src={`${IMAGE_PATH}/menu/Menu-Discount-White.png`} alt="Menu Discount White" /> : <img src={`${IMAGE_PATH}/menu/Menu-Discount-Black.png`} alt="Menu Discount Black" /> :
                                                index === 4 ? text === page ? <img src={`${IMAGE_PATH}/menu/Menu-Contact-White.png`} alt="Menu Contact White" /> : <img src={`${IMAGE_PATH}/menu/Menu-Contact-Black.png`} alt="Menu Contact Black" /> :
                                                    <></>}
                            </ListItemIcon>
                            <ListItemText primary={text} sx={{ whiteSpace: "nowrap" }} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Drawer>
        <Main open={open}>
            <DrawerHeader />
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={true} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable={false} pauseOnHover theme="light" />
            <Routes>
                <Route path="/onboarding" element={<Onboarding />} />
                <Route path="/" element={<Overview config={CONFIG} />} />
                <Route path="/analytics" element={<Analytics />} />
                <Route path="/widget" element={<Widget />} />
                <Route path="/discount" element={<Discount />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </Main>
    </Box>;

    if (REACT_APP_ENV === "development") {
        return Vandra;
    } else if (REACT_APP_ENV === "production") {
        return <Provider config={CONFIG} router={appBridgeRouter}>
            {Vandra}
        </Provider>;
    }
};

export default App;